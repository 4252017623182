import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: typeof document === 'undefined' ? import.meta.env.VITE_API_BASE_URL : '',
  headers: {
    ContentType: 'application/json',
    timeout: 1000,
    credentials: 'same-origin',
  },
});

export default axiosInstance;
